<template>
  <div class="w-100">
    <div class="d-flex justify-content-between align-items-center">
      <h5 class="mb-0">{{ $t('players') }}</h5>
    </div>
    <hr>
    <b-table striped bordered hover :items="players" :fields="columns">
      <div slot="tournaments" slot-scope="row" class="row">
        <select class="form-control col-8" v-model="row.item.tournament_id">
          <option value="">{{ $t('selectOption') }}</option>
          <option v-for="tournament in tournaments" :key="tournament.id" :value="tournament.id">{{ tournament.name }}</option>
        </select>
        <b-button class="col-2" @click="transferPlayer(row.item.id, row.item.tournament_id)">
          <i class="fa fa-arrow-right"></i>
        </b-button>
        <b-button v-if="team.money > 0" class="col-2" variant="danger" @click="removePlayerPremium(row.item)">
          <i class="fa fa-trash"></i>
        </b-button>
      </div>
      <template slot="actions" slot-scope="row" v-if="team.money === 0" >
        <div class="text-center w-100">
          <b-button v-if="!isLockSigning" @click="openModalDeletePlayer(row.item)" variant="danger"><i class="fa fa-trash px-1"></i></b-button>
        </div>
      </template>
    </b-table>
    <b-modal ref="modalDelete" :title="$t('confirmTitleDelete')" headerBgVariant="danger" headerTextVariant="light">
      <p>{{$t('confirmTextDelete')}}</p>
      <strong>{{ player.nick }}</strong>
      <div slot="modal-footer">
        <b-button @click="$refs.modalDelete.hide()">{{$t('cancel')}}</b-button>
        <b-button variant="danger" @click="deletePlayer()" class="ml-3">{{$t('delete')}}</b-button>
      </div>
    </b-modal>
    <b-modal ref="modalDeletePremiumPlayer" :title="$t('confirmTitleDelete')" headerBgVariant="danger" headerTextVariant="light">
      <p>{{$t('freePlayerPremium')}}</p>
      <strong>{{ player.nick }}</strong>
      <div slot="modal-footer">
        <b-button @click="$refs.modalDeletePremiumPlayer.hide()">{{$t('cancel')}}</b-button>
        <b-button variant="danger" @click="deletePlayerPremium()" class="ml-3">{{$t('delete')}}</b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      players: [],
      player: {},
      columns: [
        { key: 'nick', label: this.$t('player'), sortable: true },
        { key: 'position', label: this.$t('position'), sortable: true },
        { key: 'tournaments', label: this.$t('transferPlayer'), sortable: false },
        { key: 'actions', label: '' }
      ],
      teamId: this.$route.params.teamId,
      tournaments: [],
      tournamentId: this.$route.query.tournament,
      isLockSigning: false
    }
  },
  computed: {
    ...mapGetters([
      'loading',
      'lang',
      'console',
      'team',
      'user'
    ])
  },
  mounted () {
    this.fetchData()
  },
  methods: {
    removePlayerPremium (player) {
      this.player = player
      this.$refs.modalDeletePremiumPlayer.show()
    },
    deletePlayerPremium () {
      const params = {
        tournament: this.tournamentId
      }
      const path = `auth/${this.lang}/${this.console}/teams/${this.team.id}/players/${this.player.id}/remove-contract`
      this.$axios.delete(path, { params }).then(() => {
        this.players = this.players.filter(player => {
          return player.id !== this.player.id
        })
        this.$toastr.success(this.$t('updatedInfo'), window.TITLE_SUCCESS)
        this.$refs.modalDeletePremiumPlayer.hide()
      })
    },
    openModalDeletePlayer (player) {
      this.player = player
      this.$refs.modalDelete.show()
    },
    deletePlayer () {
      const params = {
        tournament: this.tournamentId
      }
      const path = `auth/${this.lang}/${this.console}/teams/${this.team.id}/players/${this.player.id}/delete`
      this.$axios.delete(path, { params }).then(() => {
        this.players = this.players.filter(player => {
          return player.id !== this.player.id
        })
        this.$toastr.success(this.$t('updatedInfo'), window.TITLE_SUCCESS)
        this.$refs.modalDelete.hide()
      })
    },
    transferPlayer (userId, tournamentId) {
      if (!userId || !tournamentId) {
        this.$toastr.warning('Selecciona un torneo.', window.TITLE_ERROR)
        return
      }
      const params = {
        user_id: userId,
        tournament_id: tournamentId
      }
      const path = `auth/${this.lang}/${this.console}/teams/${this.team.id}/players/transfer`
      this.$axios.post(path, params).then(() => {
        this.$toastr.success(this.$t('updatedInfo'), window.TITLE_SUCCESS)
      })
    },
    deleteDt (userId) {
      const path = `auth/${this.lang}/${this.console}/teams/${this.team.id}/delete-dt/${userId}`
      this.$axios.delete(path).then(() => {
        this.dts = this.dts.filter(dt => {
          return dt.user_id !== userId
        })
        this.$toastr.success(this.$t('updatedInfo'), window.TITLE_SUCCESS)
      })
    },
    fetchData () {
      const params = {
        tournament: this.tournamentId
      }
      const path = `auth/${this.lang}/${this.console}/teams/${this.teamId}/players`
      this.$axios.get(path, { params }).then(response => {
        const positions = window.positions
        const data = response.data
        this.isLockSigning = data.is_lock_signing
        this.players = data.players.map(p => {
          const position = p.position
          p.position = positions.filter(pos => {
            return pos.value === position
          }).reduce(pos => {
            return pos
          }).text
          return p
        })
        this.tournaments = data.tournaments.filter(tournament => {
          return tournament.id !== this.tournamentId
        })
      })
    }
  }
}
</script>
